import { ref } from 'vue'
import { getStudentClassList } from '@/api/index'
import { ElLoading } from 'element-plus'

export interface IclassItem {
  text: string,
  value: string
}
const classList = ref<Array<IclassItem>>([])

export const useClass =() => {
  const getClassList = () => {
    const classLoading = ElLoading.service({ fullscreen: true })
    return new Promise((resolve, reject)=>{
      getStudentClassList().then((res:any)=>{
        classList.value = res.data || []
        resolve(res)
      }).catch(err=>{
        classList.value = []
        reject(err)
      }).finally(()=>{
        classLoading.close()
      })
    })
    
  }
  return {
    classList,
    getClassList
  }
}