<template>
  <div class="hd-menu">
    <ul class="menu">
      <li
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
        :class="{ active: index === activeIndex }"
        ref="menuItems"
      >
        <span v-html="item.text"></span>
      </li>
    </ul>
    <div class="underline" :style="underlineStyle"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue' 
import { useRoute } from 'vue-router'
const emit = defineEmits(['onSelect'])
const items = reactive([
  { 
    text: '我的课程',
    path: '/course'
  },
  { 
    text: '训练场',
    path: '/train'
  },
  { 
    text: '创&nbsp;&nbsp;&nbsp;&nbsp;作',
    path: '/create'
  },
])
const activeIndex = ref<any>(null)
const menuItems = ref<any>(null)
const route = useRoute()

const initMenuIndex = (path:string) => {
  const findIndex = items.findIndex((item:any)=>path.includes(item.path))
  activeIndex.value = findIndex > -1 ? findIndex : 0
}

watch(
  () => route.path,
  (path) => {
    initMenuIndex(path)
  }
)
onMounted(() => {
  initMenuIndex(route.path)
})

const selectItem = (index:number) => {
  emit('onSelect', items[index])
  if(items[index].path === '/create') return
  activeIndex.value = index;
}
const underlineStyle = computed(()=>{
  if (activeIndex.value !== null) {
    // 获取选中菜单项的宽度
    const menuItem = menuItems.value[activeIndex.value];
    const width = menuItem.offsetWidth;
    
    return {
      width: `${width}px`, // 将宽度应用到下划线
      left: `${menuItem.offsetLeft}px`, // 根据选中的项目计算下划线的位置
    };
  } else {
    return {};
  }
})
</script>

<style lang="scss" scoped>
  .hd-menu {
    // width: 300px;
    position: relative;
    // font-size: 24px;
    .menu {
      list-style-type: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      display: flex;
      position: relative;
      li {
        margin: 5px 100px 16px 5px;
        padding: 5px;
        color: white;
        // border: 1px solid #ccc;
        // background-color: #f0f0f0;
        transition: background-color 0.3s;
        position: relative;
      }

      li.active {
        // background-color: #e0e0e0;
      }

    }
    .underline {
      height: 4px;
      background-color: white; /* 下划线颜色 */
      position: absolute;
      bottom: 0;
      transition: left 0.3s, width 0.3s; /* 下划线动画 */
    }
  }
</style>