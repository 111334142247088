<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
const store = useStore()

const setAccount = (account:any)=>{
  store.commit('SET_ACCOUNT', account)
}

const account = localStorage.getItem('account') || '{}'

if(JSON.parse(account)?.name) {
  setAccount(JSON.parse(account))
}

</script>

<style scoped>

</style>
