import Index from '@/views/User/Index.vue'
import info from '@/views/User/info.vue'
import coin from '@/views/User/coin.vue'
import works from '@/views/User/works.vue'
import special from '@/views/User/special.vue'

export const user = {
  name: "个人中心",
  path: "/user",
  component: Index,
  children: [
    {
      name: "基本信息",
      path: "/user/info",
      component: info,
    },
    {
      name: "我的考卷",
      path: "/user/special",
      component: special,
    },
    {
      name: "我的创作",
      path: "/user/works",
      component: works,
    },
    {
      name: "我的积分",
      path: "/user/coin",
      component: coin,
    }
  ]
}