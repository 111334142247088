<template>
  <div class="train-answer-card">
    <div class="train-answer-card-title"><span></span>答题卡</div>
    <div class="train-answer-card-content">
      <div class="train-answer-card-item" 
        v-for="(item, index) in cardList" 
        :class="{'have-answer': item.state === 3, 'active': currentIndex === index}" 
        :key="index"
        @click="cardItemSelect(index)">
        <span>{{index+1}}.</span>
        <span>{{item.showId}}</span>
        <span class="train-answer-card-item-type">{{item.subjectName}}</span>
        <!-- 已答题才显示图标 -->
        <!-- state: 0、答对，1、答错，2、未答，3、已答 -->
        <img v-if="item.state === 3" class="train-answer-card-item-icon" src="@/assets/answer-icon.png">
      </div>
    </div>
    <div class="train-answer-card-btn">
      <el-button :disabled="currentIndex === 0" @click="preQuestion">上一题</el-button>
      <el-button :disabled="currentIndex === cardList.length - 1" type="primary" @click="nextQuestion">下一题</el-button>
    </div>
    <div class="answer-btn" v-if="cardList.length && cardList[currentIndex].type===tiDesc.create" @click="toScratch">答题</div>
  </div>
</template>

<script lang="ts" setup>
import { IanswerCard } from "@/types/answer"
import { tiDesc } from "@/utils/answer"
const emit = defineEmits(['pre','next', 'select', 'answer'])
defineProps({
  cardList: {
    type: Array<IanswerCard>,
    default: ()=>[]
  },
  currentIndex: {
    type: Number,
    default: 0
  }
})

function preQuestion() {
  emit('pre')
}

function nextQuestion() {
  emit('next')
}

function cardItemSelect(index:number) {
  emit('select', index)
}
const toScratch = () =>{
  // recordId 卷子记录id, tiRecord 题目答题记录
  emit('answer')
}
</script>

<style lang="scss" scoped>
  .train-answer-card {
    position: sticky;
    top: 0;
    width: 254px;
    height: 555px;
    border-radius: 4px;
    padding: 20px 0;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(229, 229, 229, 1);
    box-shadow: 1px 3px 5px  rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .train-answer-card-title {
      font-size: 20px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        background-color: #337EFF;
        width: 4px;
        height: 22px;
        margin-right: 10px;
      }
    }
    .train-answer-card-content {
      flex: 1;
      margin-top: 20px;
      overflow: auto;
      .train-answer-card-item {
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 64px;
        transition: background-color 0.7s, color 0.7s;
      }
      .train-answer-card-item:hover, .active {
        background: #D6E5FF;
        color: #337EFF;
        cursor: pointer;
      }
      .train-answer-card-item-type {
        margin: 0 10px;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .train-answer-card-item-icon {
        margin-left: 10px;
        width: 14px;
      }
      .have-answer {
        color: #337EFF;
      }
    }
    .train-answer-card-btn {
      border-top: 1px solid #E5E5E5;
      display: flex;
      justify-content: space-around;
      padding-top: 18px;
    }
  }
  .answer-btn {
    position: absolute;
    bottom: 20px;
    left: -100px;
    z-index: 999;
    color: #fff;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    font-size: 16px;
    border-radius: 50%;
    background-color: #30b4ff;
    -webkit-box-shadow: 0 0 10px #858585;
    box-shadow: 0 0 10px #858585;
    cursor: pointer;
  }
</style>