import Index from '@/views/Train/Index.vue'
import Answer from '@/views/Train/Answer.vue'

export const train = [
  {
    name: "train",
    path: "/train",
    component: Index
  },
  {
    name: "train-answer",
    path: "/train/answer/:id",
    component: Answer
  }
]