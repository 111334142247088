<template>
  <div class="page-content" v-loading="loading">
    <div class="page-content-title">
      <div>
        <span> 答题详情 </span>
        <span>得分: {{ examInfo.score || 0 }} 分</span>
        <span>耗时: {{ getDuration }}</span>
      </div>
    </div>
    <el-tabs v-model="activeName" class="wtopic-wrapper practice-wrapper">
      <el-tab-pane name="选择题">
        <template #label>
          <div class="wtopic-tabs_pane">
            &#12288;选择题&#12288;
          </div>
        </template>
        <div
          class="cdl-perspective-content-list"
          v-for="(item, index) in choiceList"
          :key="index"
        >
          <reportChoice :data="item"></reportChoice>
        </div>
      </el-tab-pane>
      <el-tab-pane name="创作题">
        <template #label>
          <div class="wtopic-tabs_pane">
            &#12288;创作题&#12288;
          </div>
        </template>
        <reportCreate :data="createList"></reportCreate>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed } from 'vue'
import { getMyPractiseReport } from '@/api'
import { useRoute, useRouter } from 'vue-router'
import reportChoice from '@/components/report/choice.vue'
import reportCreate from '@/components/report/create.vue'
const route = useRoute()
const router = useRouter()
const { practiseId } = route.params
const { recordId } = route.query
const activeName = ref('选择题')
const choiceList = ref([])
const createList = ref([])
const loading = ref(false)
const examInfo = reactive({
  score: 0,
  duration: 0
})

const getReport = async () => {
  loading.value = true
  const res = await getMyPractiseReport({
    recordId,
    practiseId
  })
  loading.value = false
  if(res.code !== '200') return
  choiceList.value = res.data.stList || []
  createList.value = res.data.jdList || []
  Object.assign(examInfo, res.data.result)
}

const getDuration = computed(()=>{
  // 省略小数点
  let time =
    Math.floor(examInfo.duration / 60) +
    "分" +
    examInfo.duration % 60 +
    "秒";
  return time;
})

getReport()

</script>

<style lang="scss" scoped>
.page-content {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 30px;
  background-color: #fff;
  min-height: calc(100vh - 72px);
  .page-content-title {
    background-color: #fff;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #cfe0fd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-weight: 500;
      padding-left: 10px;
      font-size: 18px;
      border-left: 4px solid #30b4ff;
      span {
        margin-right: 50px;
      }
    }
  }
}
:deep(.wtopic-wrapper) {
  .el-tabs__header {
    background-color: transparent;
    margin: 0;
  }
  .el-tabs__content {
    overflow: visible;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__active-bar {
    height: 36px;
    border-radius: 5px;
  }
  .el-tabs__active-bar::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 8px;
    height: 8px;
  }
  .el-tabs__item {
    color: #30b4ff;
    z-index: 9;
    transition: color 0.4s;
    height: 36px;
    line-height: 36px;
  }
  .wtopic-tabs_pane:hover {
    background-color: #edf2fa;
    z-index: 4;
    border-radius: 5px;
  }
  .el-tabs__item.is-active .wtopic-tabs_pane:hover {
    color: #fff;
    background-color: transparent;
  }
  .el-tabs__item.is-active {
    color: #fff;
  }
}

</style>