import axios from 'axios'
import { ElMessage, ElNotification, ElMessageBox } from 'element-plus';
import { getToken, removeToken } from '@/utils/auth'
import { errorCode, resCode } from '@/utils/errorCode'
import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service:any = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '',
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use((config:any) => {
  if (config.method === "get") {
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded; charset=UTF-8";
  }
  if(config.type === 'form') {
    config.headers["Content-Type"] =
    "application/x-www-form-urlencoded; charset=UTF-8";
  }
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers["X-User-Token"] = getToken().token;
  }
  return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use((res:any) => {
    res.data.code +=''
    // 未设置状态码则默认成功状态
    const code = res.data.code || '200';
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (resCode.indexOf(code) !== -1) {
      ElMessage({
        message: "请登录~",
        type: 'warning',
        duration: 5 * 1000
      })
      store.commit('SET_ACCOUNT', {})
      removeToken()
      return Promise.reject('error')
    }
    if (code === '500') {
      ElMessage({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else if (code !== '200') {
      ElNotification.error({
        title: msg
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  (error:any) => {
    console.log(error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
      ElMessage({
        message: message,
        type: 'error',
        duration: 5 * 1000
      })
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
      ElMessage({
        message: message,
        type: 'error',
        duration: 5 * 1000
      })
    } else if (message.includes("Request failed with status code 401")) {
      message = `请登录~`;
      ElMessage({
        message: message,
        type: 'warning',
        duration: 5 * 1000
      })
      store.commit('SET_ACCOUNT', {})
      removeToken()
    }else {
      ElMessage({
        message: message,
        type: 'warning',
        duration: 5 * 1000
      })
    }
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
