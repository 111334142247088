<template>
  <div class="user-content">
    <div class="user-aside">
      <div class="user-info">
        <el-image
          style="width: 100px; height: 100px"
          :src="account.logoUrl"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="[account.logoUrl]"
          fit="cover"
        />
        <div>{{account.userName || account.name}}</div>
        <div class="day-day-up">好好学习，天天向上</div>
      </div>
      <el-menu :default-active="defaultActive" router>
        <el-menu-item v-for="item in userNav" :key="item.path" :index="item.path">
          <span>{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="user-view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const stroe = useStore()
const account = computed(()=>{
  return stroe.state.account
})

const route = useRoute()
const userNav = ref([
  {
    path: "/user/info",
    name: "基本信息",
  },
  {
    path: "/user/special",
    name: "我的考卷",
  },
  {
    path: "/user/works",
    name: "我的创作",
  },
  // {
  //   path: "/user/coin",
  //   name: "我的积分",
  // }
])
const defaultActive = computed(()=>{
  return route.path
})
</script>

<style lang="scss" scoped>
  .user-content {
    width: 1200px;
    min-height: calc(100vh - 88px);
    padding: 32px 0;
    margin: 0 auto;
    display: flex;
    .user-aside {
      width: 267px;
      left: 387px;
      padding: 48px 0;
      box-shadow: 1px 4px 10px  rgba(0, 0, 0, 0.15);
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .user-info {
        text-align: center;
        margin-bottom: 20px;
        .day-day-up {
          font-size: 12px;
          margin-top: 4px;
        }
      }
      .el-menu {
        border: none !important;
        width: 100%;
      }
      .el-menu-item {
        font-size: 18px !important;
        display: flex;
        justify-content: center;
      }
    }
    .user-view {
      margin-left: 10px;
      padding: 48px 30px;
      box-shadow: 1px 4px 10px  rgba(0, 0, 0, 0.15);
      background-color: #fff;
      flex: 1;
    }
  }
</style>