import { ref } from 'vue'
import { getCourseListByClassId } from '@/api/index'
import { ElLoading } from 'element-plus'

export interface Icourse {
  belongCourseId: number,
  courseId: number,
  courseName: string,
  levelId: number,
  no: number,
  startTm: string,
  state: number,
}
const courseList = ref<Array<Icourse>>([])

export const useCourse =() => {
  const getCourseList = (classId: string) => {
    const loadingInstance = ElLoading.service({ fullscreen: true })
    return new Promise((resolve, reject)=>{
      getCourseListByClassId({
        pageSize: 60,
        pageNum: 1,
        classId
      }).then((res:any)=>{
        courseList.value = res.data || []
        resolve(res)
      }).catch(err=>{
        courseList.value = []
        reject(err)
      }).finally(()=>{
        loadingInstance.close()
      })
    })
    
  }
  return {
    courseList,
    getCourseList
  }
}