import { ref } from "vue";
import { dropGrade } from '@/api'

interface IgradeItem {
  value: number,
  text: string
}

const gradeOptions = ref<Array<IgradeItem>>([])

export const useGradeOptions = () => {

  const getGradeOptions= () => {
    dropGrade({dropType: '1'}).then((res:any)=>{
      if(res.error) return
      gradeOptions.value = (res.data || []).splice(1)
    })
  }

  return {
    gradeOptions,
    getGradeOptions
  }
}