<template>
  <div>
    <div class="course-hd">
      <div class="course-info">
        <div class="head-left">
          <el-icon class="head-back" @click="goBack"><ArrowLeft /></el-icon>
          <div class="course-name">
            <span class="course-num">第{{ courseInfo?.no }}课</span>
            <span>{{ courseInfo?.courseName }}</span>
          </div>
        </div>
        <div class="head-right">
          <div class="videos">
            <div class="video handout-video" @click="handleDocument"><img src="@/assets/file.png" alt="" srcset="">讲义</div>
            <div class="video course-video" @click="handleVideo"><img src="@/assets/video.png" alt="" srcset="">课程视频</div>
          </div>
          <div>教师：{{ courseInfo.teacher }}</div>
        </div>
      </div>
    </div>
    <div class="course-content">
      <div class="ti-list">
        <div class="class-type">
          <div>
            <img class="class-png" src="@/assets/class.png" alt="" srcset="">
            课堂练习
            <img class="line-png" src="@/assets/line.png" alt="" srcset="">
          </div>
        </div>
        <div class="ti-item" v-for="item in studyList" :key="item.showId">
          <div class="item-fold">
            <div class="ti-status"><img :src="requireImg(item.status)" alt="" srcset="">{{ formatStatus(item.status) }}</div>
            <div class="ti-type">编程题</div>
            <div class="ti-name">{{ item.subjectName }}</div>
            <div class="ti-score">{{ item.score }}分</div>
            <div class="ti-control">
              <div class="control-btn zhankai" :class="{disabled: !item.judgeList.length}" @click="unfoldClick(item)">
                <el-icon v-if="item.unfold"><ArrowDown /></el-icon>
                <el-icon v-else><ArrowUp /></el-icon>
                展开
              </div>
              <div class="control-btn dati" @click="toScratch('answer', 14, item.subjectId, item.showId, '')">答题</div>
              <div class="control-btn shipin" :class="{none: true}">无视频</div>
            </div>
          </div>
          <div class="unfold-info" v-if="item.unfold">
            <div class="unfold-info-list" v-for="record in item.judgeList" :key="record.recordId">
              <div class="submit-time">提交时间: {{ record.commitTime }}</div>
              <div class="unfold-score">得分：{{ record.score }}分</div>
              <div class="detail-btn" @click="toScratch('detail', 14, item.subjectId, record.showId, record.recordId)">详情</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ti-list">
        <div class="class-type">
          <div>
            <img class="class-png" src="@/assets/class.png" alt="" srcset="">
            课后作业
            <img class="line-png" src="@/assets/line.png" alt="" srcset="">
          </div>
        </div>
        <div class="ti-item" v-for="item in taskList" :key="item.showId">
          <div class="item-fold">
            <div class="ti-status"><img :src="requireImg(item.status)" alt="" srcset="">{{ formatStatus(item.status) }}</div>
            <div class="ti-type">编程题</div>
            <div class="ti-name">{{ item.subjectName }}</div>
            <div class="ti-score">{{ item.score }}分</div>
            <div class="ti-control">
              <div class="control-btn zhankai" :class="{disabled: !item.judgeList.length}" @click="unfoldClick(item)">
                <el-icon v-if="item.unfold"><ArrowDown /></el-icon>
                <el-icon v-else><ArrowUp /></el-icon>
                展开
              </div>
              <div class="control-btn dati" @click="toScratch('answer', 12, item.subjectId, item.showId, '')">答题</div>
              <div class="control-btn shipin" :class="{none: true}">无视频</div>
            </div>
          </div>
          <div class="unfold-info" v-if="item.unfold">
            <div class="unfold-info-list" v-for="record in item.judgeList" :key="record.recordId">
              <div class="submit-time">提交时间：{{ record.commitTime }}</div>
              <div class="unfold-score">得分：{{ record.score }}分</div>
              <div class="detail-btn" @click="toScratch('detail', 12, item.subjectId, record.showId, record.recordId)">详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :right="100" :bottom="100"><el-icon><Top /></el-icon></el-backtop>
    <oj-video
      :visible="showVideo"
      :url="videoUrl"
      @close="showVideo = false"
    ></oj-video>
  </div>
</template>

<script lang="ts" setup>
import noStart from '@/assets/tiStatus/noStart.png'
import unCorrect from '@/assets/tiStatus/unCorrect.png'
import right from '@/assets/tiStatus/right.png'
import error from '@/assets/tiStatus/error.png'
import { useRoute, useRouter } from 'vue-router'
import { getStudyListByCourseId, getTaskListByCourseId, getPSign } from '@/api/index'
import { getToken } from '@/utils/auth'
import { getScratchHost } from '@/libs/tools'
import { ElMessage } from 'element-plus';
import ojVideo from '@/components/video/Index.vue'
import { ElLoading } from 'element-plus'
import { ref, onMounted, onUnmounted } from 'vue'
const route = useRoute()
const router = useRouter()
const studyList = ref([])
const taskList = ref([])
const courseInfo = ref({})
const showVideo = ref(false)
const videoUrl = ref('')
onMounted(() => {
  tabWatch()
})
onUnmounted(() => {
  removeTabwatch()
})

const classLoading = ElLoading.service({ fullscreen: true })

const requireImg = (type:number) => {
  let imgData = ''
  switch (type) {
    case 0:
      imgData = right
      break;
    case 1:
      imgData = unCorrect
      break;
    case 3:
    case 4:
      imgData = error
      break;
    case 5: 
      imgData = noStart
      break;
    default:
      break;
  }
  return imgData
}
const formatStatus = (type:number) => {
  let resStatus = ''
  switch (type) {
    case 0:
      resStatus = '答对了'
      break;
    case 1:
      resStatus = '未批改'
      break;
    case 3:
    case 4:
      resStatus = '答错了'
      break;
    case 5: 
      resStatus = '还没做'
      break;
    default:
      break;
  }
  return resStatus
}
const getStudyList = () => {
  getStudyListByCourseId({
    belongCourseId: route.params.belongCourseId,
    courseId: route.params.courseId
  }).then((res:any)=> {
    if(res.code !== '200') return
    studyList.value = res.data.taskList || []
    courseInfo.value = res.data.course || {}
  }).finally(()=>{
    classLoading.close()
  })
}

const getTaskList = () => {
  getTaskListByCourseId({
    belongCourseId: route.params.belongCourseId,
    courseId: route.params.courseId
  }).then((res:any)=> {
    if(res.code !== '200') return
    taskList.value = res.data.taskList || []
  }).finally(()=>{
    classLoading.close()
  })
}

const toScratch = (type: string, sourceType:number, subjectId:Number, showId:string, recordId:string) =>{
  window.open(`${getScratchHost()}?editType=${type}&token=${getToken().token}&courseId=${courseInfo.value.courseId}&subjectId=${subjectId}&sourceType=${sourceType}&showId=${showId}&recordId=${recordId || ''}`)
}

const handleDocument = () => {
  const height = window.screen.height;
  const width = window.screen.width;
  if (!courseInfo.value.handout) {
    return ElMessage({
      message: "暂无文档",
      type: 'warning',
      duration: 5 * 1000
    })
  }
  window.open(
    courseInfo.value.handout,
    "教义文档",
    `width=${width * 0.4},
    height=${height * 0.8},
    right=40,
    top=${height * 0.1},
    resizable,
    scrollbars=yes,status=1`
  );
}

const handleVideo = () => {
  if (!courseInfo.value.video || courseInfo.value.video === "javascript:void(0)") {
    return ElMessage({
      message: "暂无视频",
      type: 'warning',
      duration: 5 * 1000
    })
  }
  if (courseInfo.value.video.indexOf("?=") !== -1) {
    courseInfo.value.video = courseInfo.value.video.split("?=")[1];
  }
  getPSign({ fileId: courseInfo.value.video }).then(
    (res) => {
      videoUrl.value = `${window.location.origin}/video.html?id=${courseInfo.value.video}&psign=${res.data}`;
      showVideo.value = true;
    }
  );
}

const unfoldClick = (item) =>{
  if(!item.judgeList.length) return
  item.unfold = !item.unfold
}
let hiddenProperty = null;

const onVisibilityChange = () => {
  if (document[hiddenProperty]) {
    console.log("离开......");
  } else {
    // if (window.location.hash.indexOf("/course") === -1) return;
    console.log("进入......");
    getStudyList()
    getTaskList()
  }
}

const tabWatch = () => {
  // 不同浏览器 hidden 名称
  if ("hidden" in document) {
    hiddenProperty = "hidden";
  } else if ("webkitHidden" in document) {
    hiddenProperty = "webkitHidden";
  } else if ("mozHidden" in document) {
    hiddenProperty = "mozHidden";
  }
  const self = this;
  const visibilityChangeEvent = hiddenProperty.replace(
    /hidden/i,
    "visibilitychange"
  );
  
  document.addEventListener(visibilityChangeEvent, onVisibilityChange);
}

const removeTabwatch = () => {
  let hiddenProperty = null;
  if ("hidden" in document) {
    hiddenProperty = "hidden";
  } else if ("webkitHidden" in document) {
    hiddenProperty = "webkitHidden";
  } else if ("mozHidden" in document) {
    hiddenProperty = "mozHidden";
  }
  const visibilityChangeEvent = hiddenProperty.replace(
    /hidden/i,
    "visibilitychange"
  );
  if (onVisibilityChange) {
    document.removeEventListener(
      visibilityChangeEvent,
      onVisibilityChange
    );
  }
}

getStudyList()
getTaskList()

const goBack = ()=>{
  router.push({path: '/course'})
}

</script>

<style lang="scss" scoped>
  .course-hd {
    height: 72px;
    background: #FFFFFF;
    box-shadow: 1px 4px 10px  rgba(21, 21, 21, 0.15);
    font-size: 22px;
  }
  .course-info {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-family: 'QingKeHuangYou', sans-serif;
    .head-left {
      display: flex;
      align-items: center;
      .course-name {
        margin: 0 159px 0 58px;
      }
      .course-num {
        margin-right: 20px;
      }
      .head-back {
        cursor: pointer;
      }
    }
    .head-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .videos {
        display: flex;
        align-items: center;
        .video {
          padding: 12px 26px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .video:hover {
          border-radius: 4px;
          background: rgba(51, 126, 255, 0.15);
        }
        .handout-video {
          margin-right: 46px;
        }
        img {
          height: 20px;
          margin-right: 12px;
        }
      }
    }
  }
  .course-content {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 32px 32px;
    border-radius: 10px;
    box-shadow: 1px 4px 10px  rgba(0, 0, 0, 0.15);
  }
  .ti-list {
    .class-type, .ti-item {
      padding: 30px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      
    }
    .class-type,.item-fold {
      display: flex;
      align-items: center;
      // height: 92px;
    }
    
    .class-type div {
      position: relative;
      width: 190px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: -32px;
      color: #fff;
      font-size: 24px;
      border-radius: 0px 20px 0px 20px;
      background: linear-gradient(180deg, rgba(0, 91, 234, 1) 0%, rgba(0, 198, 251, 1) 100%);
      .class-png {
        margin-right: 14px;
      }
      .line-png {
        position: absolute;
        bottom: 3px;
        right: 4px;
        width: 164px;
      }
    }
    .item-fold {
      font-size: 20px;
      .ti-status {
        display: flex;
        align-items: center;
        margin-right: 28px;
        img {
          height: 32px;
          margin-right: 12px;
        }
      }
      .ti-type {
        width: 150px;
        margin-right: 10px;
      }
      .ti-name {
        width: 225px;
        margin-right: 10px;
      }
      .ti-score {
        flex: 1;
      }
    }
    .ti-control {
      display: flex;
      width: 325px;
      justify-content: space-between;
      .control-btn {
        width: 88px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 9px;
        cursor: pointer;
      }
      .zhankai {
        background-color: #337eff;
      }

      .dati {
        background-color: #ff8502;
      }
      .shipin {
        background-color: #33cc99;
      }
      .none {
        background-color: #E5E5E5;
        color: #808080;
      }
      .disabled {
        background-color: #E5E5E5;
        color: #808080;
      }
    }
    .unfold-info {
      width: 900px;
      border: 1px solid rgba(56, 56, 56, 0.2);
      margin: 30px 32px 10px;
      padding: 0 20px;
      border-radius: 10px;
      &-list {
        height: 70px;
        border-bottom: 1px solid #c8c8c8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        .detail-btn {
          width: 88px;
          text-align: center;
          padding: 6px 0;
          color: #FF8D1A;
          border: 1px solid #FF8D1A;
          border-radius: 9px;
          cursor: pointer;
        }
      }
      &-list:last-child {
        border-bottom: none;
      }
    }
  }
</style>