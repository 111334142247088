<template>
  <div class="cdl-other-subject-result">
    <div class="cdl-other-subject-result-content active">
      <div class="column is-1">{{ data.showId }} {{ data.score ? `(${data.score}分)` : '' }}</div>
      <div class="column is-11">
        <div class="cdl-other-subject-result-content-html" v-html="data.description"></div>
        <div>
          <div>
            <el-checkbox-group 
              v-model="checkBoxAnswer"
              class="topic-options"
              v-if="data.type === 1">
              <el-checkbox  v-for="(choice, index) in data.select"
                            :key="index"
                            :disabled="true"
                            :label="choices[index]">
                            <div class="set-select"
                                :style="{color: handleGetCheckboxColor(choices[index], checkBoxAnswer)}">
                              <span>{{ choices[index] }}</span>
                              <div v-html="choice[choices[index]]"></div>
                              <el-icon v-if="data.status === 0 && checkBoxAnswer.includes(choices[index])"><Check /></el-icon>
                              <el-icon v-if="data.status !== 0 && checkBoxAnswer.includes(choices[index])"><Close /></el-icon>
                            </div>
              </el-checkbox>
            </el-checkbox-group>
            <el-radio-group 
              v-model="data.answer[0]"
              class="topic-options"
              v-if="data.type === 0">
              <el-radio v-for="(choice, index) in data.select"
                        :key="index"
                        :disabled="true"
                        :label="choices[index]">
                        <div class="set-select"
                            :style="{color: handleGetRadioColor(choices[index], data.answer[0])}">
                          <span class="media-left">{{ choices[index] }}</span>
                          <div class="topic-options-item" v-html="choice[choices[index]]"></div>
                          <el-icon v-if="data.status === 0 && choices[index] == data.answer[0]"><Check /></el-icon>
                          <el-icon v-if="data.status !== 0 && choices[index] == data.answer[0]"><Close /></el-icon>
                        </div>
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="data.reviseStatus !== 5">
            <el-checkbox-group v-model="checkBoxReviseAnswer"
                              v-if="data.type === 1">
              <el-checkbox  v-for="(choice, index) in data.select"
                            :key="index"
                            :disabled="true"
                            :label="choices[index]">
                            <div class="set-select"
                                :style="{color: handleGetCheckboxReviseColor(choices[index], checkBoxReviseAnswer)}">
                              <span>{{ choices[index] }}</span>
                              <el-icon v-if="data.reviseStatus === 0 && checkBoxReviseAnswer.includes(choices[index])"><Check /></el-icon>
                              <el-icon v-if="data.reviseStatus !== 0 && checkBoxReviseAnswer.includes(choices[index])"><Close /></el-icon>
                            </div>
              </el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-model="data.reviseAnswer[0]"
                            v-if="data.type === 0">
              <el-radio v-for="(choice, index) in data.select"
                        :key="index"
                        :disabled="true"
                        :label="choices[index]">
                        <div class="set-select"
                            :style="{color: handleGetRadioReviseColor(choices[index], data.reviseAnswer[0])}">
                          <spann class="media-left">{{ choices[index] }}</spann>
                          <el-icon v-if="data.reviseStatus === 0 && choices[index] == data.reviseAnswer[0]"><Check /></el-icon>
                          <el-icon v-if="data.reviseStatus !== 0 && choices[index] == data.reviseAnswer[0]"><Close /></el-icon>
                        </div>
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Close, Check } from '@element-plus/icons-vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const choices = ref(['A', 'B', 'C', 'D'])
const checkBoxAnswer = computed(() => {
  if (props.data.type && props.data.type === 1) {
    return props.data.answer.split('')
  }
})

const checkBoxReviseAnswer = computed(() => {
  if (props.data.type && props.data.type === 1) {
    return props.data.reviseAnswer.split('')
  }
})

const handleGetCheckboxColor = (value:any, list:any) => {
  if (props.data.status === 0 && list.includes(value)) {
    return '#86CA01'
  }
  if (props.data.status !== 0 && list.includes(value)) {
    return '#FF8839'
  }
}

const handleGetRadioColor = (value:any, answer:any) => {
  if (props.data.status === 0 && value === answer) {
    return '#86CA01'
  }
  if (props.data.status !== 0 && value === answer) {
    return '#FF8839'
  }
}

const handleGetCheckboxReviseColor = (value:any, list:any) => {
  if (props.data.reviseStatus === 0 && list.includes(value)) {
    return '#86CA01'
  }
  if (props.data.reviseStatus !== 0 && list.includes(value)) {
    return '#FF8839'
  }
}

const handleGetRadioReviseColor = (value:any, answer:any) => {
  if (props.data.reviseStatus === 0 && value === answer) {
    return '#86CA01'
  }
  if (props.data.reviseStatus !== 0 && value === answer) {
    return '#FF8839'
  }
}
</script>

<style lang="scss" scoped>
.cdl-other-subject-result {
  padding: 0 20px;
  &-content {
    display: flex;
    margin-top: 0px;
    padding: 15px 0;
    border-radius: 6px;
    min-height: 120px;
    position: relative;
    border-bottom: 1px dashed #CFE0FD;
    .column {
      margin-right: 10px;
    }
    i.icon-right {
      font-size: 14px;
      color: #86CA01;
    }
    i.icon-wrong {
      font-size: 14px;
      color: #FF8839;
    }
    &-blank {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &.active {
      border-bottom: none;
    }
    &-jdstatus {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 10px;
      height: 30px;
      & > div {
        span:nth-child(1) {
          font-size: 1.25rem;
          font-weight: bold;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 50px;
          line-height: 30px;
          color: #fff;
          text-align: center;
          border-radius: 2px;
          margin-left: 30px;
          margin-right: 20px;
        }
        span:nth-child(3) {
          font-size: 16px;
          margin-right: 20px;
        }
        button {
          width: 70px;
          height: 30px;
          vertical-align: bottom;
          border-radius: 2px;
          color: #fff;
          background: linear-gradient(90deg, #17aafe 0%, #42baff 100%);
        }
      }

    }
    &-status {
      position: absolute;
      right: 60px;
      bottom: 15px;
      i.icon-right {
        font-size: 22px;
      }
      i.icon-wrong {
        font-size: 22px;
      }
    }
    .set-vacancy-padding {
      padding-left: 28px;
    }
    .el-checkbox,.el-radio {
      display: flex;
      align-items: center;
      margin: 0;
      margin-top: 10px;
      margin-left: 10px;
      .set-select {
        display: flex;
        span {
          margin-right: 10px;
        }
        div {
          display: inline-block;
        }
      }
      .el-icon {
        margin-left: 10px;
      }
    }
    &-span {
      width: 300px;
      height: auto;
      margin-left: 5px;
      padding: 2px 10px;
      vertical-align: middle;
      border-radius: 4px;
      outline: none;
      &:disabled {
        background-color: #f3f7f9;
      }
    }
    .set-a a {
      color: #409EFF ;
      font-size: 16px;
      text-decoration: underline;
      border: none ;
    }
    .set-fixed {
      width: 22px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;
      border-radius: 6px 0 0 6px;
      background-color: #80D0FD;
      position: absolute;
      right: -31px;
      top: 50%;
      z-index: 999;
      transform: translateY(-50%)
    }
    .set-fixed.active {
      right: -11px;
    }
    .analysis {
      width: 100%;
      height: 100%;
      transform-origin: 100% 50%;
      background-color: #fff;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 2;
      > div {
        width: 95%;
        height: 90%;
        padding: 20px;
        border-radius: 6px;
        overflow: auto;
        position: absolute;
        left: 2%;
        top: 5%;
        background-color: #E5F6FF;
      }
    }
  }
  &-edit {
    padding: 20px;
    button {
      width:75px;
      height:28px;
      font-size: 12px;
      color: #fff;
      background:rgba(48,180,255,1);
      border-radius:2px;
    }
  }
  &-annotation {
    pre {
      display: flex;
      padding: 0;
      .hljs {
        white-space: normal;
      }
    }
  }
}
.el-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.topic-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .topic-options-item {
    // height: 200px;
    color: #666;
    img {
      height: 100%;
    }
  }
  :deep(.el-radio) {
    height: auto;
    align-items: flex-start;
  }
  :deep(.el-checkbox) {
    height: auto;
    align-items: flex-start;
  }
}
</style>

<style>
.cdl-other-subject-result-content-html p {
  white-space: normal;
}
.set-vacancy-padding p {
  white-space: normal;
}
</style>
