import { RouteRecordRaw } from 'vue-router'
import Course from '@/views/Course/course.vue'
import CourseDetail from '@/views/Course/courseDetail.vue'
import Home from '@/views/Home/home.vue'
import Layout from '@/views/Layout/layout.vue'
import Project from "@/views/Project/project.vue"
import Player from "@/views/Player/player.vue"
import report from '@/views/Report/report.vue'
import { user } from './user'
import { train } from './train'

export const routes: Array<RouteRecordRaw> = [
  { 
    path: '/', 
    component: Layout,
    children: [
      { 
        path: '', 
        redirect: 'course',
      },
      { 
        path: 'course', 
        component: Course,
      },
      { 
        path: 'course/:courseId/:belongCourseId', 
        component: CourseDetail,
      },
      { 
        path: 'course', 
        component: Course,
      },
      { 
        path: 'home', 
        component: Home,
      },
      { 
        name: 'report',
        path: 'examReport/:practiseId', 
        component: report,
      },
      user,   // 用户信息
      ...train,  // 训练场
    ],
  },
  {
    path: '/project_detail',
    component: Project,
  },
  {
    path: '/player',
    component: Player,
  }
]
