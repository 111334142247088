import service from '@/utils/request'

export const login = (data:any) => {
  return service({
    url: '/api/user/doLogin',
    method: 'post',
    data,
    params: data
  })
}

export const getStudentClassList = () => {
  return service({
    url: '/newSrv/scratch/subject/getStudentClassList',
    method: 'post'
  })
}

export const getCourseListByClassId = (params:any) => {
  return service({
    url: '/newSrv/scratch/subject/getCourseListByClassId',
    method: 'get',
    params
  })
}

// 获取课程课堂练习
export const getStudyListByCourseId = (params:any) => {
  return service({
    url: '/newSrv/scratch/subject/getStudyListByCourseId',
    method: 'get',
    params
  })
}

// 获取课程课后作业
export const getTaskListByCourseId = (params:any) => {
  return service({
    url: '/newSrv/scratch/subject/getTaskListByCourseId',
    method: 'get',
    params
  })
}

// 获取视频sign
export const getPSign = (params:any) => {
  return service({
    url: '/newSrv/dict/common/getVideoSegmentSign',
    method: 'get',
    params
  })
}

// export const fetchSb3File = (url:any) => {
//   return service({
//     url,
//     method: 'get',
//     type: 'file',
//     responseType: 'stream'
//   })
// }

// 获取视频sign
export const getUserInfoByLogion = () => {
  return service({
    url: '/newSrv/auth/user/getUserInfoByLogion',
    method: 'get',
  })
}

export const dropGrade = (data:any) => {
  return service({
    url: '/newSrv/school/common/dropGrade',
    method: 'post',
    data
  })
}

export const getProvince = () => {
  return service({
    url: '/srv/common/pullDown/province',
    method: 'get',
  })
}

export const getCity = (params:any) => {
  return service({
    url: '/srv/common/pullDown/city',
    method: 'get',
    params
  })
}

export const getDistrict = (params:any) => {
  return service({
    url: '/srv/common/pullDown/district',
    method: 'get',
    params
  })
}

export const editStudentData = (data:any) => {
  return service({
    url: '/newSrv/auth/school/userAndRole/editStudentData',
    method: 'post',
    data
  })
}

export const editStudentPassword = (data:any) => {
  return service({
    url: '/newSrv/auth/school/userAndRole/editStudentPassword',
    method: 'post',
    data
  })
}

export const sendPhoneCode = (params:any) => {
  return service({
    url: '/api/user/bundle/verify',
    method: 'get',
    params
  })
}

export const bundlePhone = (data:any) => {
  return service({
    url: '/api/user/bundle/tel',
    method: 'post',
    data,
    type: 'form'
  })
}

export const getWorkDataListForUser = (params:any) => {
  return service({
    url: '/newSrv/scratch/subject/getWorkDataListForUser',
    method: 'get',
    params
  })
}

export const getWorkDataDetailForUser = (params:any) => {
  return service({
    url: '/newSrv/scratch/subject/getWorkDataDetailForUser',
    method: 'get',
    params
  })
}

export const updateWorkDataDetail = (params:any) => {
  return service({
    url: '/newSrv/scratch/subject/updateWorkDataDetail',
    method: 'get',
    params
  })
}

export const getSignForH5Share = (data:any) => {
  return service({
    url: '/newSrv/applet/wx/getSignForH5Share',
    method: 'post',
    data
  })
}

export const doLoginBySchoolToOj = (params:any) => {
  return service({
    url: '/api/user/doLoginBySchoolToOj',
    method: 'get',
    params
  })
}

export const getPractiseList = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/getPractiseList',
    method: 'post',
    data
  })
}

export const startPractise = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/startPractise',
    method: 'post',
    data
  })
}

export const doStart = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/doStart',
    method: 'post',
    data
  })
}

export const getSubjectSortList = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/getSubjectSortList',
    method: 'post',
    data
  })
}

export const getScratchCDetail = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/getScratchCDetail',
    method: 'post',
    data
  })
}

export const getProgramDetail = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/getProgramDetail',
    method: 'post',
    data
  })
}

export const doUpdateTime = (data:any) => {
  return service({
    url: '/newSrv/scratch/subject/doUpdateTime',
    method: 'post',
    data
  })
}

export const createScratchC = (data:any) => { // 选择题保存
  return service({
    url: '/newSrv/scratch/subject/createScratchC',
    method: 'post',
    data
  })
}

export const doFinish = (data:any) => { // 交卷
  return service({
    url: '/newSrv/scratch/subject/doFinish',
    method: 'post',
    data
  })
}

export const getMyPractiseList = (data:any) => { 
  return service({
    url: '/newSrv/scratch/subject/getMyPractiseList',
    method: 'post',
    data
  })
}

export const getMyPractiseReport = (data:any) => { 
  return service({
    url: '/newSrv/scratch/subject/getMyPractiseReport',
    method: 'post',
    data
  })
}