import type { CascaderProps } from 'element-plus'
import { 
  getProvince, 
  getCity, 
  getDistrict,
} from '@/api'

export const props: CascaderProps = {
  lazy: true,
  lazyLoad(node:any, resolve:any) {
    const { level, value } = node
    if(level === 0) {
      getProvince().then((res:any)=>{
        if(res.error) return
        const nodes = (res.data || []).map((item:any)=>{
          return {
            value: item.value,
            label: item.text,
            leaf: level >= 2
          }
        })
        resolve(nodes)
      })
    }

    if(level === 1) {
      getCity({ province: value }).then((res:any)=>{
        if(res.error) return
        const directCity = ['810000', '110000', '120000', '310000', '500000']
        const nodes = (res.data || []).map((item:any)=>{
          return {
            value: item.value,
            label: item.text,
            leaf: directCity.indexOf(value) > -1
          }
        })
        resolve(nodes)
      })
    }

    if(level === 2) {
      getDistrict({city: value}).then((res:any)=>{
        if(res.error) return
        const nodes = (res.data || []).map((item:any)=>{
          return {
            value: item.value,
            label: item.text,
            leaf: level >= 2
          }
        })
        resolve(nodes)
      })
    }
 
  },
}

export const useGetProvince = getProvince
export const useGetCity = getCity
export const useGetDistrict = getDistrict