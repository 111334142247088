<template>
  <div class="player-content">
    <div class="project-title">
      <img :src="projectInfo.headImage">
      <div class="create-info">
        <div>{{projectInfo.userName}}</div>
        <div>创作时间：{{projectInfo.updateTime.slice(0, 10)}}</div>
      </div>
    </div>
    <div class="project-show" v-if="projectInfo.workPath">
      <iframe 
        :src="projectInfo.workPath"
        width="100%"
        height="100%"
        scrolling="no" 
        frameborder="0"
        ></iframe>
    </div>
    <div class="project-info">
      <div class="info-title">
        <h3>作品描述</h3>
      </div>
      <div class="info-content">{{projectInfo.publishDesc}}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { getWorkDataDetailForUser, getSignForH5Share } from '@/api'
const route = useRoute()
interface IprojectInfo {
  name: string
  updateTime: string
  publishDesc: string
  qrCodeImage: string
  workImage: string
  workPath: string
  headImage: string
  userName: string
}

const projectInfo = ref<IprojectInfo>({
  name: '',
  updateTime: '',
  publishDesc: '',
  qrCodeImage: '',
  workImage: '',
  workPath: '',
  headImage: '',
  userName: ''
})

const getProjectDetail = () => {
  getWorkDataDetailForUser({id: route.query.id}).then((res:any)=>{
    if(res.error) return
    res.data.workPath = `${location.origin}/player.html?workUrl=${encodeURIComponent(res.data.workPath)}`
    projectInfo.value = res.data
    getWeixinConfig()
  })
}
const getWeixinConfig = () => {
  getSignForH5Share({id: route.query.id}).then((res:any)=>{
    wx.config({
      debug: false,
      appId: res.data.appId,
      timestamp: res.data.timestamp, 
      nonceStr: res.data.noncestr,
      signature: res.data.signature,
      jsApiList: ['updateAppMessageShareData'] 
    });
    wx.ready(function () {
      wx.updateAppMessageShareData({ 
        title: projectInfo.value.name, // 分享标题
        desc: projectInfo.value.publishDesc || '接口返回空',
        link: location.href,
        imgUrl: projectInfo.value.workImage,
        success: function (res:any) {
          console.log(res)
        },
        fail: function(err:any) {
          console.log(err)
        }
      })
    })
    wx.error(function(err:any){
      console.log('111====>', err)
    })
  })
}
getProjectDetail()


</script>

<style lang="scss" scoped>
  .player-content {
    height: 100vh;
    padding: 20px;
  }

  .project-title {
    display: flex;
    // align-items: center;
    img {
      height: 80px;
      width: 80px;
      margin-right: 20px;
    }
    .create-info {
      display: flex;
      margin: 10px 0;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .project-show {
    height: 360px;
    margin-top: 20px;
  }
  .project-info {
    margin-top: 20px;
    .info-title {
      margin-bottom: 10px;
    }
  }
  
</style>