<template>
  <div>
    <div class="base-info">
      <div class="info-title">
        <div>基本信息</div>
        <el-icon v-if="!isEdit" @click="isEdit = !isEdit"><Edit /></el-icon>
      </div>
      <div class="base-form">
        <el-form
          label-position="top"
          label-width="100px"
          :model="baseInfo"
        >
          <div class="form-line">
            <el-form-item label="昵称">
              <el-input size="large" :disabled="!isEdit" v-model="baseInfo.nickname" placeholder="请输入昵称" />
            </el-form-item>
            <el-form-item label="账号">
              <el-input disabled size="large" v-model="baseInfo.account" placeholder="请输入账号" />
            </el-form-item>
          </div>
          <div class="form-line">
            <el-form-item label="生日">
              <el-date-picker
                disabled
                type="date"
                placeholder="请选择生日"
                size="large"
              />
            </el-form-item>
            <el-form-item label="性别">
              <el-select :disabled="!isEdit" v-model="baseInfo.gender" placeholder="请选择性别" size="large">
                <el-option label="男" :value="1" />
                <el-option label="女" :value="2" />
              </el-select>
            </el-form-item>
          </div>
          <div class="form-line">
            <el-form-item label="学校">
              <el-input disabled size="large" v-model="baseInfo.address" placeholder="请输入学校" />
            </el-form-item>
            <el-form-item label="年级">
              <el-select disabled v-model="baseInfo.grade" placeholder="请选择年级" size="large">
                <el-option 
                  v-for="item in gradeOptions" 
                  :label="item.text" 
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="地址" class="addr-item">
            <el-cascader 
              disabled
              v-model="baseInfo.regionArr"
              :props="props"
              size="large" placeholder="请选择住址" 
            />
          </el-form-item>
          <div class="form-btns" v-if="isEdit">
            <el-button type="primary" @click="editBaseInfo">保存</el-button>
            <el-button @click="isEdit=false">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="safety-info">
      <div class="info-title">安全信息</div>
      <div>
        <div class="safety-pwd safety-line">
          <div class="safety-line-label">密码：</div>
          <div class="safety-line-value">{{baseInfo.passwd}}</div>
          <el-button class="safety-line-edit" @click="pwdVisible=true">修改密码</el-button>
        </div>
        <div class="safety-phone safety-line">
          <div class="safety-line-label">手机号：</div>
          <div class="safety-line-value">{{baseInfo.tel}}</div>
          <el-button class="safety-line-edit" @click="telVisible=true">绑定手机号</el-button>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="pwdVisible"
    title="更改密码"
    width="30%"
    @close="pwdDialogClose"
  >
    <el-form 
      ref="pwdFormRef" 
      :model="pwdForm" 
      label-width="80px" 
      :rules="pwdFormRules"
      >
      <el-form-item label="原始密码" prop="oldPassword">
        <el-input type="password" v-model="pwdForm.oldPassword" placeholder="请输入原始密码" clearable />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="pwdForm.newPassword" placeholder="请输入新密码" clearable />
      </el-form-item>
      <el-form-item label="确认密码" prop="newPasswordSure">
        <el-input type="password" v-model="pwdForm.newPasswordSure" placeholder="再次输入新密码" clearable />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm(pwdFormRef)">取消</el-button>
        <el-button type="primary" @click="submitPwd(pwdFormRef)">
          确认
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 修改手机号 -->
  <el-dialog 
    width="30%"
    v-model="telVisible"
    title="绑定手机号"
    @close="telDialogClose">
    <el-form 
      label-width="80px"
      ref="telFormRef"
      :model="telForm"
      :rules="telFormRules">
      <el-form-item label="手机号：" prop="tel">
        <el-input v-model="telForm.tel" placeholder="输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="验证码：" prop="code">
        <div class="code-input">
          <el-input v-model="telForm.code" style="width: 60%;" placeholder="输入验证码"></el-input>
          <el-button type="primary"
            @click="handleSendPhoneCode"
            :disabled="telForm.disabled"
            style="width: 115px;margin-left: 25px;">
            {{telForm.text}}</el-button>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitTel(telFormRef)">立即更换</el-button>
        <el-button @click="resetTelForm(telFormRef)">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup lang="ts">
import type { FormInstance } from 'element-plus'
import { ElMessage } from 'element-plus'
import { useBaseInfo } from '@/useComposition/use-base-info.ts'
import { useGradeOptions } from '@/useComposition/use-grade-options.ts'
import { useUserPwd } from '@/useComposition/user/use-user-pwd.ts'
import { useUserTel } from '@/useComposition/user/use-user-tel.ts'

const { props, baseInfo, isEdit, getUserInfo, editBaseInfo } = useBaseInfo()
const { gradeOptions, getGradeOptions } = useGradeOptions()
const { 
  pwdVisible, 
  pwdForm, 
  pwdFormRef, 
  pwdFormRules, 
  pwdDialogClose, 
  submitPwdForm, 
  resetForm 
} = useUserPwd()
const {
  telFormRef,
  telVisible,
  telForm,
  telFormRules,
  resetTelForm,
  telDialogClose,
  handleSendPhoneCode,
  submitTelForm
} = useUserTel()

const submitPwd = (formEl: FormInstance | undefined) => {
  submitPwdForm(formEl)?.then((res:any)=>{
    if(res.error) return
    pwdVisible.value = false
    getUserInfo()
    ElMessage({
      message: res.message,
      type: 'success',
    })
  })
}

const submitTel = (formEl: FormInstance | undefined) => {
  submitTelForm(formEl)?.then((res:any)=>{
  if (res.error) return
    telVisible.value = false
    getUserInfo()
    ElMessage({
      type: 'success',
      message: '绑定手机号成功'
    })
  })
}

getGradeOptions()
getUserInfo()

</script>

<style lang="scss" scoped>
  .info-title {
    font-size: 30px;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 23px;
    .el-icon {
      cursor: pointer;
    }
  }
  .base-form {
    margin-top: 30px;
    width: 90%;
    .form-line {
      display: flex;
      justify-content: space-between;
      .el-form-item {
        width: 45%;
        // margin-right: 50px;
      }
      :deep(.el-date-editor.el-input) {
        width: 100% !important;
      }
      .el-select {
        width: 100%;
      }
    }
    :deep(.el-cascader)  {
      width: 100%;
    }
    .form-btns {}
  }
  .safety-info {
    margin-top: 30px;
    .safety-line {
      display: flex;
      margin-top: 20px;
      align-items: center;
      &-label {
        width: 100px;
      }
      &-value {
        width: 200px;
      }
      &-edit {
        width: 100px;
      }
    }
  }
  .code-input {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    .el-input {
      flex: 1;
    }
  }
</style>