export interface IerrorCode {
  '400': string;
  '401': string;
  '403': string;
  '404': string;
  default: string;
}
export const resCode = ['204', '206', '401', '402']
export const errorCode:any = {
  '400': '用户名或者密码错误',
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
}

