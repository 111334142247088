<template>
  <transition name="scaleIn">
    <div class="video-wrapper"
         v-if="visible && url">
      <div class="video-closeBtn" @click.self="handleColse"></div>
      <iframe :src="url"
              class="video-iframe"
              allowFullScreen="true"
              frameborder="0">
      </iframe>
    </div>
  </transition>
</template>

<script>
import {removeClass, addClass} from '@/utils/handleClass.ts'

export default {
  name: 'oj-video',
  props: {
    visible: Boolean,
    url: String
  },

  methods: {
    handleColse () {
      this.$emit('close')
    }
  },

  watch: {
    visible (val) {
      if (val) {
        addClass(document.body, 'is-box-hidden')
      } else {
        removeClass(document.body, 'is-box-hidden')
      }
    },

    beforeDestroy () {
      removeClass(document.body, 'is-box-hidden')
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 999;
    transition: opacity .36s;
    line-height: 0;
  }
  &-closeBtn {
    position: absolute;
    width: 48px;
    height: 48px;
    background-image: url("@/assets/close.png");
    background-size: cover;
    background-position: center center;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  &-iframe {
    background-color: rgba($color: #000000, $alpha: 0.6);
    display: block;
    width: 901px;
    height: 541px;
    margin: 100px auto;
    overflow: hidden;
  }
}

.scaleIn-enter {
  opacity: 0;
}
.scaleIn-enter-active {
  & .video-iframe {
    animation: .36s scaleInUp;
  } 
}
.scaleIn-enter-to {
  opacity: 1;
}

.scaleIn-leave {
  opacity: 1;
}
.scaleIn-leave-active {
  & .video-iframe {
    animation: .36s scaleInDown;
  } 
}
.scaleIn-leave-to {
  opacity: 0;
}

@keyframes scaleInUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
@keyframes scaleInDown {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}
</style>
