import { FormInstance, FormRules } from "element-plus"
import { reactive, ref } from "vue"
import { sendPhoneCode, bundlePhone } from '@/api'
import { ElMessage } from 'element-plus'

interface ItelForm {
  tel: string
  code: string,
  disabled: Boolean,
  text: string
}
const telFormRef = ref<FormInstance>()

const telVisible = ref(false)

const telForm = reactive({
  tel: '',
  code: '',
  disabled: false,
  text: '发送验证码'
})

const telFormRules = reactive<FormRules<ItelForm>>({
  tel: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
})

export function useUserTel() {
  const resetTelForm = (formEl: FormInstance | undefined) => {
    telVisible.value = false
    if (!formEl) return
    formEl.resetFields()
  }
  
  const telDialogClose = () => {
    telForm.tel = ''
    telForm.code = ''
  }
  
  const handleSendPhoneCode = () => {
    if (!telForm.tel.trim()) {
      return ElMessage('请先填写手机号码')
    }
    let count = 60
    let timer:any = null

    function codeTimer () {
      timer = setInterval(() => {
        count--
        telForm.text = `${count}秒后重新发送`
        if (count === 0) {
          clearTimeout(timer)
          telForm.disabled = false
          telForm.text = '发送验证码'
        }
      }, 1000)
    }
    sendPhoneCode({
      account: telForm.tel
    }).then((res:any) => {
      if (res.error) return
      telForm.disabled = true
      codeTimer()
    })
  }
  
  const submitTelForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    return new Promise<void>((resolve, reject) => {
      formEl.validate((valid) => {
        if (valid) {
          bundlePhone({
            account: telForm.tel,
            code: telForm.code
          }).then((res:any) => {
            resolve(res)
            
          }).catch((err:any)=>{
            reject(err)
          })
        }
      })
    })
  }
  return {
    telFormRef,
    telVisible,
    telForm,
    telFormRules,
    resetTelForm,
    telDialogClose,
    handleSendPhoneCode,
    submitTelForm
  }
}