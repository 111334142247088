<template>
  <div>
    <div>
      <el-button type="primary" :plain="queryParams.type!==1" @click="projectTypeChange(1)">课程作品</el-button>
      <el-button type="primary" :plain="queryParams.type!==0" @click="projectTypeChange(0)">创作作品</el-button>
    </div>
    <div class="project" v-if="projectList.length">
      <div class="project-item" @click="go2Detail(project)" v-for="project in projectList" :key="project.id">
        <img :src="project.workImage" alt="">
        <div class="project-name">{{project.name}}</div>
        <el-button type="text" v-if="queryParams.type===0" @click="projectEditClick($event, project)">编辑<el-icon><ArrowRight /></el-icon></el-button>
      </div>
    </div>
    <div class="no-data" v-else>
      暂无数据
    </div>
    <div class="page-line">
      <el-pagination 
        v-model:page-size="queryParams.pageSize" 
        v-model:current-page="queryParams.pageNum"
        layout="prev, pager, next" 
        :total="total" 
        @current-change="handleCurrentChange"
        />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router'
import { getWorkDataListForUser } from '@/api'
import { getScratchHost } from '@/libs/tools'
import { getToken } from '@/utils/auth'

const router = useRouter()
const queryParams = reactive({
  pageNum: 1,
  pageSize: 8,
  type: 1
})
const total = ref(0)
interface IprojectItem {
  del: number
  id: number
  name: string
  publishSort: number
  publishTs: string
  publishType: number
  sourceId: number
  sourceType: number
  userId: number
  workPath: string
  workImage: string
}
const projectList = ref<Array<IprojectItem>>([])
const go2Detail = (project:IprojectItem) => {
  window.open(`/project_detail?id=${project.id}&type=${queryParams.type}`)
}

const getProjectList = () => {
  getWorkDataListForUser(queryParams).then((res:any)=>{
    if(res.error) return
    projectList.value = res.data || []
    total.value = res.total
  })
}

const handleCurrentChange = (val: number) => {
  getProjectList()
}

const projectTypeChange = (type:number) => {
  queryParams.pageNum = 1
  queryParams.type = type
  getProjectList()
}

const projectEditClick = (e:Event, project:IprojectItem) => {
  e.stopPropagation()
  console.log(e)
  const url = `${getScratchHost()}?editType=create&token=${getToken().token}&id=${project.id}`
  window.open(url)
}

getProjectList()

</script>

<style lang="scss" scoped>
  .project {
    width: 100%;
    min-height: 300px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .project-item {
      cursor: pointer;
      width: 23%;
      margin-top: 30px;
      margin-right: 15px;
      box-shadow: 1px 2px 5px  rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 195px;
        width: 100%;
      }
    }
    .project-name {
      padding: 10px 0;
    }
  }
  .no-data {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-line {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
</style>