
<template>
  <div>
    <div class="tabs-content">
      <coding-tabs class="tabs" :tabs="tabs" @onChange="tabsChange"></coding-tabs>
    </div>
    <div class="page-content">
      <div class="exam-list">
        <div class="exam-item" :class="{'orange': item.status === 1}" v-for="item in examList" :key="item.id">
          <div class="exam-item-title">{{item.name}}</div>
          <div class="exam-item-title">题数：{{item.stNum}}/{{item.programNum}}</div>
          <div class="exam-item-btn" @click="goAnswer(item)">开始答题</div>
          <div class="position-img">
            <img src="@/assets/answer.png">
            <span v-if="item.status === 1">已答</span>
          </div>
        </div>
      </div>
      <!-- <div>无数据</div> -->
    </div>
    <el-backtop :right="100" :bottom="100"><el-icon><Top /></el-icon></el-backtop>
    <el-dialog
      v-model="dialogVisible"
      width="400"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="dialog-content">您有未完成的答题记录哟~</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="handleLastPractice">继续答题</el-button>
          <el-button @click="handleNewPractice">新的答题</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, onMounted, ref } from 'vue'
import codingTabs from '@/components/menu/codingTabs.vue'
import { getPractiseList, startPractise, doStart } from '@/api'
import { ElLoading } from 'element-plus'
import { useRouter } from 'vue-router'
onMounted(() => {
  tabWatch()
})
onUnmounted(() => {
  removeTabwatch()
})
const router = useRouter()

interface IexamItem {
  id: number
  practiseId: number
  name: string
  type: number
  stNum: number       // 选择题数量
  programNum: number  // 创作题数量
  status: number      // 0: 未开始 1: 进行中 
}

const tabs = ref([
  {
    value: '1',
    text: "模拟卷"
  },
  {
    value: '2',
    text: "真题卷"
  }
])
const type = ref(1)
const examList = ref<IexamItem[]>([])
const currentRecord = ref(0)
const currentPractice = ref(0)
const currentId = ref(0)
const dialogVisible = ref(false)

const tabsChange = (item:{
  text: string,
  value: number
}) => {
  console.log(item)
  type.value = item.value
  getList()
}

const getList = () => {
  const loadingInstance = ElLoading.service({ fullscreen: true })
  getPractiseList({type: type.value}).then((res:any)=>{
    loadingInstance.close()
    if(res.code !== '200') return
    examList.value = res.data || []
  })
}
function goToPractice (currentId:number, recordId:number) {
  const routeData = router.resolve({
    name: 'train-answer',
    query: {recordId: recordId},
    params: {id: currentId}
  })
  dialogVisible.value = false
  window.open(routeData.href, '_blank')
}
const createRecord = (id:number) => {
  doStart({practiseId: id}).then((res:any) => {
    if (res.error) return
    if (res.data) {
      goToPractice(id, res.data)
    }
  })
}

const goAnswer = (item:IexamItem) => {
  startPractise({practiseId: item.practiseId}).then((res:any)=>{
    if(res.code !== '200') return
    if(res.data.status === 0) {
      createRecord(item.practiseId)
    }else {
      currentRecord.value = res.data.recordId
      currentPractice.value = item.practiseId
      currentId.value = item.id
      dialogVisible.value = true
    }
  })
}
function handleLastPractice () {
  goToPractice(currentPractice.value, currentRecord.value)
}

function handleNewPractice () {
  createRecord(currentPractice.value)
}
const handleClose = () => {
  console.log('close')
}

let hiddenProperty:any = null;
const onVisibilityChange = () => {
  if (document[hiddenProperty]) {
    console.log("离开......");
  } else {
    console.log("进入......");
    getList()
  }
}
function tabWatch () {
  // 不同浏览器 hidden 名称
  if ('hidden' in document) {
    hiddenProperty = 'hidden'
  } else if ('webkitHidden' in document) {
    hiddenProperty = 'webkitHidden'
  } else if ('mozHidden' in document) {
    hiddenProperty = 'mozHidden'
  }
  const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
 
  document.addEventListener(visibilityChangeEvent, onVisibilityChange)
}

function removeTabwatch () {
  let hiddenProperty:any = null
  if ('hidden' in document) {
    hiddenProperty = 'hidden'
  } else if ('webkitHidden' in document) {
    hiddenProperty = 'webkitHidden'
  } else if ('mozHidden' in document) {
    hiddenProperty = 'mozHidden'
  }
  const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
  if (onVisibilityChange) {
    document.removeEventListener(visibilityChangeEvent, onVisibilityChange);
  }
}
getList()


</script>

<style lang="scss" scoped>
  .tabs-content {
    display: flex;
    box-shadow: 1px 4px 10px  rgba(21, 21, 21, 0.15);
    padding: 12px 0;
    .tabs {
      width: 1200px;
      margin: 0 auto;
    }
  }
  
  .page-content {
    width: 1200px;
    box-sizing: border-box;
    margin: 33px auto 0;
    padding: 49px 88px 0;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 4px 10px  rgba(0, 0, 0, 0.15);
  }
  .exam-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  }
  .exam-item {
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 180px;
    border-radius: 10px;
    padding: 20px 25px;
    transition: all .5s;
    margin: 0 20px 60px 20px;
    box-shadow: 1px 4px 5px  rgba(0, 0, 0, 0.15);
    background: linear-gradient(-45deg, #E8EDFAFF 0%, #EDFCF7FF 49.23%, #FAE8F3FF 100%);
    .exam-item-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .exam-item-btn {
      width: 88px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      opacity: 0;
      border-radius: 136px;
      background: #337EFF;
      font-size: 16px;
      cursor: pointer;
      transition: all .5s;
    }
    .position-img {
      position: absolute;
      right: -30px;
      bottom: -60px;
      width: 180px;
      img {
        width: 100%;
      }
      span {
        position: absolute;
        font-size: 16px;
        left: 40px;
        top: 20px;
        transform: rotate(-12deg) skewY(10deg);
        color: #A3AAE8;
        opacity: 0.6;
      }
    }
  }
  .exam-item:hover {
    transform: translate3d(0, -15px, 0);
    box-shadow: 1px 4px 5px  rgba(0, 0, 0, 0.3);
    .exam-item-btn {
      opacity: 1;
    }
  }
  .orange {
    background: #F7E4D2;
  }
  :deep(.el-dialog) {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(237, 249, 255, 1) 21.18%, rgba(255, 255, 255, 1) 100%);
  }
  .dialog-content {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #383838;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .el-button {
      margin: 0 20px;
    }
  }
</style>