import { reactive, ref } from "vue"
import type { FormInstance, FormRules } from 'element-plus'
import { editStudentPassword } from '@/api'
import md5 from 'js-md5'

interface IpwdForm {
  oldPassword: string
  newPassword: string
  newPasswordSure: string
}

const pwdFormRef = ref<FormInstance>()

const pwdForm = reactive({
  oldPassword: '',
  newPassword: '',
  newPasswordSure: ''
})
const pwdVisible = ref(false)

const pwdFormRules = reactive<FormRules<IpwdForm>>({
  oldPassword: [
    { required: true, message: '请输入原始密码', trigger: 'blur' },
  ],
  newPassword: [
    { required: true, message: '请输入新密码', trigger: 'blur' },
  ],
  newPasswordSure: [
    { required: true, message: '请再次输入新密码', trigger: 'blur' },
  ]
})

export const useUserPwd = () => {

  const pwdDialogClose = () => {
    pwdForm.oldPassword = ''
    pwdForm.newPassword = ''
    pwdForm.newPasswordSure = ''
  }
  
  const submitPwdForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    return new Promise<void>((resolve, reject) => {
      formEl.validate((valid) => {
        if (valid) {
          console.log('submit!')
          editStudentPassword({
            oldPassword: md5(pwdForm.oldPassword),
            newPassword: md5(pwdForm.newPassword),
            newPasswordSure: md5(pwdForm.newPasswordSure)
          }).then((res:any)=>{
            resolve(res)
           
          }).catch((err:any)=>{
            reject(err)
          })
        }
      })
    })
  }

  const resetForm = (formEl: FormInstance | undefined) => {
    pwdVisible.value = false
    if (!formEl) return
    formEl.resetFields()
  }
  
  return {
    pwdVisible,
    pwdForm,
    pwdFormRef,
    pwdFormRules,
    pwdDialogClose,
    submitPwdForm,
    resetForm
  }
}