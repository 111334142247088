
// import storage from "store";

const TokenKey = 'account'

export function getToken() {
  let account = localStorage.getItem(TokenKey) || '{}'
   return JSON.parse(account)
}

export function setToken(data: any) {
  return localStorage.setItem(TokenKey, JSON.stringify(data))
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}
