import { ref } from 'vue'
import { getUserInfoByLogion, editStudentData } from '@/api'
import { props } from './use-addr'
import { ElMessage } from 'element-plus'

export interface IbaseInfo {
  nickname: string,
  account: string,
  address: string,
  region: string,
  regionArr: Array<string>,
  gender: string,
  grade: string,
  tel: string,
  passwd: string,
}

const baseInfo = ref<IbaseInfo>({
  nickname: '',
  account: '',
  address: '',
  region: '',
  regionArr: [],
  gender: '',
  grade: '',
  tel: '',
  passwd: ''
})

const isEdit = ref(false)

export const useBaseInfo = () => {
  const getUserInfo = () => {
    getUserInfoByLogion().then((res:any)=>{
      if(res.error) return
      baseInfo.value = {...res.data}
      baseInfo.value.regionArr = []
      if(res.data.region) {
        baseInfo.value.regionArr[0] = res.data.region.slice(0, 2)+'0000'
        baseInfo.value.regionArr[1] = res.data.region.slice(0, 4)+'00'
        baseInfo.value.regionArr[2] = res.data.region.slice(0)
      } else {
        baseInfo.value.regionArr = []
      }
    })
  }
  const editBaseInfo = () => {
    editStudentData({
      nickName: baseInfo.value.nickname,
      gender: baseInfo.value.gender
    }).then((res:any)=>{
      if(res.error) return
      getUserInfo()
      ElMessage({
        message: res.message,
        type: 'success',
      })
      isEdit.value = false
    })
  }
  return {
    props,
    baseInfo,
    isEdit,
    getUserInfo,
    editBaseInfo
  }
}