<template>
  <div class="page-header">
    <div class="hd-content">
      <div class="hd-logo">
        <img src="@/assets/logo.png" alt="" srcset="">
      </div>
      <div class="hd-right">
        <head-menu @onSelect="menuClick"></head-menu>
        <el-dropdown popper-class="user-drop-dowm" v-if="account.userName || account.name">
          <span class="hd-user">
            <img :src="account.logoUrl">
            {{ account.userName || account.name }}
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="router.push('/user/info')"><img src="@/assets/user.png" alt="" srcset="">基本信息</el-dropdown-item>
              <el-dropdown-item @click="router.push('/user/special')"><img src="@/assets/special.png" alt="" srcset="">我的考卷</el-dropdown-item>
              <el-dropdown-item @click="router.push('/user/works')"><img src="@/assets/create.png" alt="" srcset="">我的创作</el-dropdown-item>
              <!-- <el-dropdown-item @click="router.push('/user/coin')"><img src="@/assets/coin.png" alt="" srcset="">我的积分</el-dropdown-item> -->
              <el-dropdown-item @click="logout"><img src="@/assets/exit.png" alt="" srcset="">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div v-else>
          <div class="login" @click="dialogVisible=true">登录</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
    @close="resetForm(formRef)"
  >
    <div class="login-content">
      <div class="login-logo"><img src="@/assets/login.png" alt="" srcset=""></div>
      <el-form ref="formRef" :rules="loginRules" :model="loginForm" label-width="120px">
        <el-form-item prop="account">
          <el-input size="large" v-model="loginForm.account" placeholder="请输入手机号/邮箱/用户名" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="large" v-model="loginForm.password" show-password placeholder="请输入密码" />
        </el-form-item>
      </el-form>
      <el-button class="dialog-submit" size="large" type="primary" @click="submitForm(formRef)"> 点击登录 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex'
import HeadMenu from './headMenu.vue'
import md5 from 'js-md5'
import { useRouter } from 'vue-router'
import { removeToken, getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus';
import { getScratchHost } from '@/libs/tools'
const router = useRouter()

const stroe = useStore()
const account = computed(()=>{
  return stroe.state.account
})
import type { FormInstance, FormRules } from 'element-plus'
interface RuleForm {
  account: string
  password: string
}
const loginForm = reactive<RuleForm>({
  account: '',
  password: ''
})
const loginRules = reactive<FormRules<RuleForm>>({
  account: [
    { required: true, message: '请输入手机号/邮箱/用户名', trigger: 'blur' },
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
  ]
})
const formRef = ref<FormInstance>()
const dialogVisible = ref(false)


const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      stroe.dispatch('login', {
        account: loginForm.account,
        password: md5(loginForm.password)
      }).then((res)=>{
        if(res.code !== '200') return
        router.go(0)
        dialogVisible.value = false
      })
    }
  })
}

const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.resetFields()
}
const menuClick = (item:any)=>{
  if(item.path === '/create') {
    toScratch()
  } else {
    router.push(item.path)
  }
}
const toScratch = ()=>{
  if(getToken().token) {
    const url = `${getScratchHost()}?editType=create&token=${getToken().token}`
    console.log(url)
    window.open(url)
  } else {
    ElMessage({
      message: "请先登录~",
      type: 'warning',
      duration: 5 * 1000
    })
  }
}
const logout = () => {
  stroe.commit('SET_ACCOUNT', {})
  removeToken()
  router.push('/course')
}
</script>

<style lang="scss" scoped>
.page-header {
  height: 88px;
  color: white;
  background-color: #337eff;
  .hd-content {
    height: 100%;
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .hd-logo {
      height: 55px;
      margin-right: 200px;
      img {
        height: 100%;
      }
    }
  }
  .hd-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    .hd-user {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #fff;
      outline-width: 0;
      cursor: pointer;
      img {
        height: 48px;
        width: 48px;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}
.login {
  cursor: pointer;
}
.login-content {
  margin: 0 20px 30px;
  .login-logo {
    text-align: center;
    margin-bottom: 40px;
    img {
      width: 100%;
      // height: 60px;
    }
  }
  .dialog-submit {
    margin-top: 20px;
    width: 100%;
  }
  :deep(.el-form-item__content) {
    margin-left: 0 !important;
  }
}
</style>
<style lang="scss">
  .user-drop-dowm {
    img {
      height: 16px;
      width: 16px;
      margin-right: 6px;
    }
  }
</style>