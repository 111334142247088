<template>
  <div>
    <Header v-if="route.name !== 'train-answer'"></Header>
    <div class="view-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import Header from './components/header.vue'
import { useRoute } from 'vue-router'
const route = useRoute()
</script>

<style lang="scss" scoped>
  .view-content {
    background-color: #fff;
    min-height: calc(100vh - 88px);
    background-image: url('@/assets/background-logo.png');
    background-size: cover; /* 将背景图像缩放以覆盖整个元素 */
    background-repeat: no-repeat; /* 防止图像平铺 */
    background-attachment: fixed; /* 可选：固定背景图像 */
    background-position: center top 130px;
  }
  
</style>