<template>
  <div class="class-list">
    <div class="class-item" v-for="(item, index) in props.tabs" @click="selectItem(index)">
      <div class="class-name" :class="{active: index===activeIndex}">{{ item.text }}</div>
      <span class="cut-line" v-if="index!==props.tabs.length-1"></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue' 
import { IclassItem } from '@/types/course'
const emit = defineEmits(['onChange'])
const props = defineProps({
  tabs: {
    type: Array<IclassItem>,
    default: ()=>[]
  }
})
const activeIndex = ref(0)

// onMounted(() => {
//   selectItem(0)
// })

const selectItem = (index:number) => {
  emit('onChange', props.tabs[index])
  activeIndex.value = index;
}
</script>

<style lang="scss" scoped>
  .class-list {
    display: flex;
    align-items: center;
    margin: 12px 0;
    font-size: 22px;
    .class-item {
      display: flex;
      align-items: center;
    }
    .class-name {
      width: 180px;
      text-align: center;
      // height: 48px;
      line-height: 48px;
      opacity: 1;
      border-radius: 10px 10px 10px 0px;
      cursor: pointer;
    }
    .class-name:hover {
      background: rgba(51, 126, 255, 0.15);
    }
    .active {
      background-color: #337EFF !important;
      color: #fff;
    }
    .cut-line {
      margin: 0 11px;
      height: 46px;
      opacity: 0.2;
      border: 1px solid #000000;
    }
  }
</style>