<template>
  <div>
    <div class="project-title">
      <div>{{projectInfo.name.split('.')[0]}}</div>
      <div>{{projectInfo.updateTime}}</div>
    </div>
    <div class="project-info">
      <div class="project-show">
        <iframe 
          :src="`/player.html?workUrl=${projectInfo.workPath}`" 
          width="100%"
          height="100%"
          scrolling="no"  
          frameborder="0"
          ></iframe>
      </div>
      <div class="project-describe">
        <div>
          <div class="info-title">
            <div>作品描述</div>
            <el-button type="text" @click="descEdit">编辑</el-button>
          </div>
          <div class="info-content">{{projectInfo.publishDesc}}</div>
        </div>
        <div class="project-user-info">
          <div><img :src="account.logoUrl">{{account.userName || account.name}}</div>
          <div class="code-image"><img :src="projectInfo.qrCodeImage" alt="">手机扫码观看</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisible"
    title="编辑"
    width="30%"
    @close="dialogClose"
  >
    <el-form 
      ref="workDescFormRef" 
      :model="workDescForm" 
      label-width="80px" 
      :rules="workDescFormRules"
      >
      <el-form-item label="作品描述" prop="workDesc">
        <el-input 
          :rows="2"
          type="textarea"
          v-model="workDescForm.workDesc" 
          placeholder="请输入作品描述" 
          clearable />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm(workDescFormRef)">取消</el-button>
        <el-button type="primary" @click="submitForm(workDescFormRef)">
          确认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { getWorkDataDetailForUser, updateWorkDataDetail } from '@/api'
const route = useRoute()
const stroe = useStore()
const account = computed(()=>{
  return stroe.state.account
})
interface IprojectInfo {
  name: string
  updateTime: string
  publishDesc: string
  qrCodeImage: string
  workPath: string
}

const projectInfo = ref<IprojectInfo>({
  name: '',
  updateTime: '',
  publishDesc: '',
  qrCodeImage: '',
  workPath: ''
})

const dialogVisible = ref(false)
const workDescFormRef = ref<FormInstance>()
interface IworkDescForm{
  workDesc: string
}
const workDescForm = reactive({
  workDesc: '',
})
const workDescFormRules = reactive<FormRules<IworkDescForm>>({
  workDesc: [
    { required: true, message: '请输入描述信息', trigger: 'blur' },
  ],
})

const getProjectDetail = () => {
  getWorkDataDetailForUser({id: route.query.id}).then((res:any)=>{
    if(res.error) return
    res.data.workPath = encodeURIComponent(res.data.workPath)
    projectInfo.value = res.data
  })
}

const descEdit = () => {
  workDescForm.workDesc = projectInfo.value.publishDesc
  dialogVisible.value = true
}

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('submit!')
      updateWorkDataDetail({
        id: route.query.id,
        workDesc: workDescForm.workDesc,
      }).then((res:any)=>{
        if(res.error) return
        getProjectDetail()
        dialogVisible.value = false
        ElMessage({
          message: res.message,
          type: 'success',
        })
      })
    }
  })
}
const resetForm = (formEl: FormInstance | undefined) => {
  dialogVisible.value = false
  if (!formEl) return
  formEl.resetFields()
}
const dialogClose = () => {
  workDescForm.workDesc = ''
}

getProjectDetail()

onMounted(() => {

})
</script>

<style lang="scss" scoped>
  .project-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 50px;
    border-bottom: 1px solid #ccc;
  }
  .project-info {
    margin: 0 40px;
    display: flex;
    height: calc(100vh - 50px);
  }
  .project-show {
    width: 60%;
  }
  .project-describe {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0 0 30px;
    .info-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
    }
    .info-content {
      width: 70%;
    }
    .project-user-info {
      display: flex;
      justify-content: space-between;
    }
  }
  .code-image {
    img {
      width: 100px;
      margin-bottom: -10px;
    }
  }
</style>