import { createStore } from 'vuex'
import { login } from '@/api/index'
import { setToken } from '@/utils/auth'

const store = createStore({
  state () {
    return {
      account: {}
    }
  },
  mutations: {
    SET_ACCOUNT(state, account) {
      state.account = account
    },
  },
  actions: {
    login ({commit}, params) {
      return new Promise((resolve, reject) => {
        login(params).then((res:any)=>{
          if(res.code !== '200') return
          commit('SET_ACCOUNT', res.data)
          setToken(res.data)
          resolve(res)
        }).catch((err) => {
          reject(new Error('登录失败'))
        })
      })
      
    },
  }
})

export default store