<template>
  <div class="cdl-other-subject-result">
    <div class="cdl-other-subject-result-content" v-for="item in data" :key="item.showId">
      <div class="showid-score">
        <div class="showid-score-title">{{ item.showId }} {{ item.score ? `(${item.score}分)` : '' }}</div>
        <div>
          <div>{{ item.correctScore || 0 }} 分</div>
        </div>
      </div>
      <div class="set-vacancy-padding">
        <div class="set-a">
          <div>{{ item.showId }} {{ item.name }}</div>
        </div>
        <div>
          <p class="has-text-weight-bold is-size-5" style="line-height:30px;margin-top:10px;">题目描述:</p>
          <div v-html="item.description"></div>
        </div>
        <div>
          <p class="has-text-weight-bold is-size-5" style="line-height:30px;margin-top:10px;">准备工作:</p>
          <div v-html="item.prepareDesc"></div>
        </div>
        <div>
          <p class="has-text-weight-bold is-size-5" style="line-height:30px;margin-top:10px;">功能实现:</p>
          <div v-html="item.realizeDesc"></div>
        </div>
        <div class="cdl-other-subject-result-content-jdstatus">
          <div>
            <span>答题结果：</span>
            <el-button @click="handleToDetail(item)">查看</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getScratchHost } from '@/libs/tools'
import { getToken } from '@/utils/auth'
interface IanswerCard {
  showId: string
  score: number
  correctScore: number
  name: string
  description: string
  prepareDesc: string
  realizeDesc: string
  status: number
  judgeScore: number
}
import { ref } from 'vue'
const props = defineProps({
  data: {
    type: Array<IanswerCard>,
    default: () => ({})
  }
})
 
const status = ref({ 0: '正确', 3: '错误', 5: '错误' })
const handleToDetail = (item) => {
  const url = `${getScratchHost()}?editType=correct&token=${getToken().token}&courseId=0&subjectId=${item.subjectId}&sourceType=1&showId=${item.showId}&recordId=${item.judgeRecordId || ''}`
  window.open(url)
}
</script>

<style lang="scss" scoped>
.cdl-other-subject-result {
  padding: 0 20px;
  &-content {
    display: flex;
    margin-top: 0px;
    padding: 15px 25px;
    border-bottom: 1px dashed #CFE0FD;
    .showid-score {
      .showid-score-title {
        margin-bottom: 10px;
        width: 150px;
      }
    }
    i.icon-right {
      font-size: 14px;
      color: #86CA01;
    }
    i.icon-wrong {
      font-size: 14px;
      color: #FF8839;
    }
    &-blank {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &.active {
      border-bottom: none;
    }
    &-jdstatus {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 10px;
      height: 30px;
      & > div {
        span:nth-child(1) {
          font-size: 1.25rem;
          font-weight: bold;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 50px;
          line-height: 30px;
          color: #fff;
          text-align: center;
          border-radius: 2px;
          margin-left: 30px;
          margin-right: 20px;
        }
        span:nth-child(3) {
          font-size: 16px;
          margin-right: 20px;
        }
        button {
          width: 70px;
          height: 30px;
          vertical-align: bottom;
          border-radius: 2px;
          color: #fff;
          background: linear-gradient(90deg, #17aafe 0%, #42baff 100%);
        }
      }

    }
    &-status {
      position: absolute;
      right: 60px;
      bottom: 15px;
      i.icon-right {
        font-size: 22px;
      }
      i.icon-wrong {
        font-size: 22px;
      }
    }
    .set-vacancy-padding {
      // padding-left: 28px;
    }
    .has-text-weight-bold {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .el-checkbox,.el-radio {
      display: block;
      margin: 0;
      margin-top: 20px;
      margin-left: 10px;
      .set-select {
        display: inline-block;
        span {
          margin-right: 10px;
        }
        div {
          display: inline-block;
        }
      }
    }
    .set-a a {
      color: #409EFF ;
      font-size: 16px;
      text-decoration: underline;
      border: none ;
    }
    .set-fixed {
      width: 22px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;
      border-radius: 6px 0 0 6px;
      background-color: #80D0FD;
      position: absolute;
      right: -31px;
      top: 50%;
      z-index: 999;
      transform: translateY(-50%)
    }
    .set-fixed.active {
      right: -11px;
    }
    .analysis {
      width: 100%;
      height: 100%;
      transform-origin: 100% 50%;
      background-color: #fff;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 2;
      > div {
        width: 95%;
        height: 90%;
        padding: 20px;
        border-radius: 6px;
        overflow: auto;
        position: absolute;
        left: 2%;
        top: 5%;
        background-color: #E5F6FF;
      }
    }
  }
  &-edit {
    padding: 20px;
    button {
      width:75px;
      height:28px;
      font-size: 12px;
      color: #fff;
      background:rgba(48,180,255,1);
      border-radius:2px;
    }
  }
  &-annotation {
    pre {
      display: flex;
      padding: 0;
      .hljs {
        white-space: normal;
      }
    }
  }
}
.analysis-enter-active, .analysis-leave-active {
  transition: all .5s;
}
.analysis-enter, .analysis-leave-to {
  transform: scale(0)
}
.cdl-pre {
  display: flex;
  padding: 0;
}
.blank-first-result {
  text-align: right;
}
.answer-first {
  text-align: right;
  margin-top: 10px;
}
</style>

<style>
.cdl-other-subject-result-content-html p {
  white-space: normal;
}
.set-vacancy-padding p {
  white-space: normal;
}
</style>
