<template>
  <div>
    <div class="tabs-content">
      <el-scrollbar class="tabs">
        <coding-tabs :tabs="classList" @onChange="tabsChange"></coding-tabs>
      </el-scrollbar>
    </div>
    <div class="page-content">
      <div class="course-item" v-for="item in courseList" :key="item.no" @click="goDetail(item.courseId, item.belongCourseId)">
        <div class="study-type"><img :src="requireImg(item.state)" alt="" srcset="">{{formatText(item.state)}}</div>
        <div class="course-name">{{ item.courseName }}</div>
        <div class="course-time">上课时间：{{ item.startTm }}</div>
        <div class="right-allow"><el-icon><ArrowRight /></el-icon></div>
      </div>
    </div>
    <el-backtop :right="100" :bottom="100"><el-icon><Top /></el-icon></el-backtop>
  </div>
</template>

<script lang="ts" setup>
import codingTabs from '@/components/menu/codingTabs.vue'
import learned from '@/assets/study/learned.png'
import notlearned from '@/assets/study/notlearned.png'
import learning from '@/assets/study/learning.png'
import unauthorized from '@/assets/study/unauthorized.png'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { doLoginBySchoolToOj } from '@/api'
import { setToken } from '@/utils/auth'
import { useClass, IclassItem } from '@/useComposition/use-class.ts'
import { useCourse } from '@/useComposition/use-course.ts'
const { classList, getClassList } = useClass()
const { courseList, getCourseList } = useCourse()

const store = useStore()
const route = useRoute()
const router = useRouter()

const tabsChange = (item:IclassItem) => {
  getCourseList(item.value)
}

const goDetail = (courseId:number=0, belongCourseId=0) => {
  router.push({
    path: `/course/${courseId}/${belongCourseId}`
  })
}

const requireImg = (type:number) => {
  switch (type) {
    case 0:
      return unauthorized 
    case 1:
      return notlearned
    case 2:
      return learning
    case 3:
      return learned
    default:
      return ''
  }
}

const formatText = (type:number) => {
  let text = ''
  switch (type) {
    case 0:
      text ='未授权'
      break;
    case 1:
      text ='未学习'
      break;
    case 2:
      text ='学习中'
      break;
    case 3:
      text ='已学习'
      break;
    default:
      break;
  }
  return text
}

const getList = () => {
  getClassList().then((res:any)=>{
    if(res.code !== '200') return
    if(classList.value.length) {
      getCourseList(classList.value[0].value)
    }
  })
}

if(route.query.token) {
  doLoginBySchoolToOj({ojUserId: route.query.token}).then((res:any)=>{
    console.log(res)
    router.replace(route.path)
    if(res.code !== '200') return
    store.commit('SET_ACCOUNT', res.data)
    setToken(res.data)
    getList()
  })
}else { 
  getList()
}


</script>

<style lang="scss" scoped>
  .tabs-content {
    display: flex;
    box-shadow: 1px 4px 10px  rgba(21, 21, 21, 0.15);
    padding: 12px 0;
    .tabs {
      width: 1200px;
      margin: 0 auto;
    }
  }
  
  .page-content {
    width: 1200px;
    box-sizing: border-box;
    margin: 33px auto 0;
    padding: 0 26px 24px 26px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 4px 10px  rgba(0, 0, 0, 0.15);
  }
  .course-item {
    box-sizing: border-box;
    height: 92px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .study-type {
      height: 40px;
      padding: 4px 7px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background: rgba(75, 160, 253, 0.2);
      margin-right: 60px;
      font-size: 20px;
      img {
        height: 100%;
        margin-right: 7px;
      }
    }
    .course-name {
      flex: 1;
      font-size: 20px;
    }
    .course-time {
      margin-right: 30px;
      font-size: 18px;
    }
    .right-allow {
      color: #5E5E5E;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>